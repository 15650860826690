@mixin width {
  max-width: 300px;
  min-width: 200px;
}
@mixin row-direction {
  display: flex;
  flex-direction: row;
}
@mixin day-width {
  min-width: 40px;
  max-width: 40px;
}

#time-line {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  padding: 20px;
  overflow-x: auto;
  #time-line-header {
    @include row-direction;
    #time-line-header-left {
      @include width;
    }
    #time-line-header-right {
      @include row-direction;
      .time-line-date {
        @include day-width;
      }
    }
  }
  #time-line-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-x: hidden;
    .time-line-row {
      @include row-direction;
      height: 50px;
      align-items: center;
      border-bottom: 1px solid rgb(235, 235, 235);
      .time-line-left {
        @include width;
        > :nth-child(1) {
          font-weight: 700;
        }
        > :nth-child(2) {
          font-size: small;
        }
      }
      .time-line-right {
        background-color: #adc0a7;
        height: 50px;
        color: white;
        padding: 6px;
        position: relative;
        width: 300px;
        border-radius: 6px;
        display: flex;
        transform: translateX(-40px);
        > :nth-child(1) {
          position: absolute;
          left: 0px;
          top: 0px;
          bottom: 0px;
          width: 80%;
          background-color: #77966d;
          border-radius: 6px;
        }
        > :nth-child(2) {
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px 10px;
          > :nth-child(1) {
            font-weight: 700;
            margin-bottom: 2px;
            z-index: 10;
          }
          > :nth-child(2) {
            font-size: small;
            z-index: 10;
          }
        }
        > :nth-child(3) {
          position: absolute;
          right: 0px;
          bottom: 0px;
          top: 0px;
          display: flex;
          align-items: center;
          padding: 0px 10px;
        }
      }
      .time-line-right-quotation {
        background-color: rgb(247, 170, 170);
        height: 50px;
        color: white;
        padding: 6px;
        position: relative;
        width: 300px;
        border-radius: 6px;
        display: flex;
        transform: translateX(-40px);
        > :nth-child(1) {
          position: absolute;
          left: 0px;
          top: 0px;
          bottom: 0px;
          width: 80%;
          background-color: rgb(228, 92, 92);
          border-radius: 6px;
        }
        > :nth-child(2) {
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px 10px;
          > :nth-child(1) {
            font-weight: 700;
            margin-bottom: 2px;
            z-index: 10;
          }
          > :nth-child(2) {
            font-size: small;
            z-index: 10;
          }
        }
        > :nth-child(3) {
          position: absolute;
          right: 0px;
          bottom: 0px;
          top: 0px;
          display: flex;
          align-items: center;
          padding: 0px 10px;
        }
      }
      // .time-line-right-draft {
      //     background-color: #585858;
      //     height: 50px;
      //     color: white;
      //     padding: 6px;
      //     position: relative;
      //     width: 300px;
      //     border-radius: 6px;
      //     display: flex;
      //     transform: translateX(-40px);
      //     > :nth-child(1) {
      //         position: absolute;
      //         left: 0px;
      //         top: 0px;
      //         bottom: 0px;
      //         width: 80%;
      //         background-color: #474747;
      //         border-radius: 6px;
      //     }
      //     > :nth-child(2) {
      //         position: absolute;
      //         top: 0px;
      //         left: 0px;
      //         bottom: 0px;
      //         width: 100%;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: center;
      //         padding: 0px 10px;
      //         > :nth-child(1) {
      //             font-weight: 700;
      //             margin-bottom: 2px;
      //             z-index: 10;
      //         }
      //         > :nth-child(2) {
      //             font-size: small;
      //             z-index: 10;
      //         }
      //     }
      //     > :nth-child(3) {
      //         // position: absolute;
      //         right: 0px;
      //         bottom: 0px;
      //         top: 0px;
      //         display: flex;
      //         align-items: center;
      //         padding: 0px 10px;
      //     }
      // }
    }
  }
}

.workerIcon path {
  stroke: rgb(235, 235, 235);
}
