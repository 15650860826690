.dropDown {
  min-height: 50px;
  max-height: 50px;
  background-color: rgb(209, 209, 209);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > select {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline-color: rgb(167, 167, 167);
    border-radius: 8px;
    padding: 0px 10px;
    font-weight: 500;
  }
}
