.share-modal-container{
    width: 99%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    .share-modal-heading-container{
        
        p{
            font-weight: 700;
            text-align: center;
        }

    }
    .share-modal-bottom-container{
        margin-top: 3em;
        margin-bottom: 0.65em;
    
        .share-modal-bottom-container-send-btn{ 
                padding: 0.5em;
                width: 120px;
                height: 30px;
                background-color: #FFD34E;
                color: black;
                border: none;
                border-radius: 5px;
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                &:hover {
                    cursor: pointer;
                  }
            }
            .loading-state-btn{
                display: flex;
                justify-content: center;
                opacity: 0.4;
            }
    }

    .share-modal-send-to{
        width: 94%;
        margin-bottom: 1.5em;
    }
}