.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #EBEBF0;
    color: black;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
    height: 50px;
    min-width: 200px;
    font-size: 16px;
  }
  
  .dropdown-toggle:hover {
    background-color: #EBEBF0;
  }
  
  .dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 5px;
    list-style: none;
    padding: 0;
    min-width: 200px;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  