@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

#AnalyticsPage {
  width: 100%;
  zoom: 0.75;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.vendor-search-category{
  width: 50%;
  box-sizing: border-box;
  // width: 75vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}
#searchVendor {
  width: 50%;
  box-sizing: border-box;
  // width: 75vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}

#searchSE {
  box-sizing: border-box;
  // width: 75vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}

#searchBoxView {
  box-sizing: border-box;
  // width: 75vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}

#searchAdmin {
  box-sizing: border-box;
  width: 75vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}

#Categories {
  box-sizing: border-box;
  min-width: 50vw;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 24px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}

.assign-projects-btn{
  padding: 0.5em;
  width: 120px;
  height: 30px;
  background-color: #FFD34E;
  color: black;
  border: none;
  border-radius: 5px ;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  &:hover{
    cursor: pointer;
  }
}

.vendor-table-container{
  overflow-y: scroll;
  width: 100%;
  height: 90vh;
  margin: 2em 0;
  .vendor-table-individual-category{
    margin: 2em 0em;
  }
  .vendor-table-category-heading{
    position: sticky;
    text-align: left;
    top: 0;
    padding: 0.4em 0;

  }
  table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  tr,th,td {
    // background-color: white;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
    tr:nth-child(2n){
      background-color: #f2f2f2;
      
    
  }
  
  .vendor-table-category-header-items
    {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #ffd34d;
      color: #000;
      text-align: center;
    }
    td,th{
      padding: 8px;
    }
    .vItem-sNo{
      min-width: 26px;
      max-width: 26px;
    }

    .vItem-name{
      min-width: 140px;
      max-width: 145px;
    }
    .vItem-mobile{
      min-width: 100px;
      max-width: 100px
    }
    .vItem-email{
      max-width: 210px;
      min-width: 170px;
  
    }
    .vItem-role{

    }
    .vItem-city{

    }
    .vItem-status{

    }
    .vItem-acc-statement{
      max-width: 75px;

    }
}

