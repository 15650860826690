.card-container {
    min-width: 525px;
    font-size: small;
    padding: 1px 5px;
    position: relative;
    margin: 0.4em 1em;
    border-radius: 10px;
    background-color: #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.req-card-extraDetailsContainer {
    // margin: auto;
    display: grid;
    grid-template-columns: 0.2fr 7.4fr 0.7fr 0.7fr 0.35fr;
    // width: 85%;
}
.req-card-mainDetailsContainer {
    // display: flex;
    display: grid;
    // grid-template-columns: 0.1fr 8fr 0.6fr 0.1fr 0.1fr;
    grid-template-columns: 0.2fr 7.4fr 0.7fr 0.7fr 0.35fr;
    // grid-template-columns: minmax(0px, 7fr) minmax(0px, 0.7fr) minmax(0px, 0.44fr) minmax(0px, 0.3fr);
    // flex-direction: row;
    // justify-content: space-evenly;
    align-items: center;

    .checkbox-container {
        // align-self: flex-start;
        // margin-top: 2em;
        input {
            height: 20px;
            width: 20px;
            margin-left: 1em;
            // outline-style: auto;
        }
    }
}
.transparentText {
    font-size: small;
    color: #757575;
}
.req-card-btn {
    border: none;
    padding: 6px 10px;
    font-weight: 700;
    font-size: 13px;
    color: white;
    line-height: 16px;
    letter-spacing: 0.0015em;
    border-radius: 10px;
    &:hover {
        cursor: pointer;
    }
}

.req-card-action-btns-container {
    width: 45%;
    display: flex;
    gap: 10px;

    justify-content: flex-end;
}
.req-card-approve-btn {
    width: 100%;
    background: #117539;
}
.req-card-update-btn {
    background: #008080;
    width: 100%;
}
.req-card-hold-btn {
    background: #008080;
}
.req-card-decline-btn {
    background: #c90000;
}
#req-card-checkbox {
    height: 16px;
    width: 16px;
    // outline-style: auto;
}
.req-card-visibleContainer {
    //     gap: 10%;
    padding: 0.5em 0;

    display: grid;
    // width: 85%;
    row-gap: 5px;
    //     background-color: #fff8e1;
    //     .details-left-pan {
    //         width: 40%;
    //     }
    //     .details-right-pan {
    //         width: 40%;
    //     }
}
.mainExtraDetailsDiv {
    grid-column-start: 2;
}
.req-card-header {
    color: #757575;
    margin: 0 3%;
    display: grid;
    grid-template-columns:
        minmax(0px, 4fr) minmax(0px, 4fr) minmax(0px, 4.5fr) minmax(0px, 3fr) minmax(0px, 3fr)
        minmax(0px, 2.5fr);
}
.req-card-mainDetails {
    margin: 0 3%;
    display: grid;
    grid-template-columns:
        minmax(0px, 4fr) minmax(0px, 4fr) minmax(0px, 4.5fr) minmax(0px, 3fr) minmax(0px, 3fr)
        minmax(0px, 2.5fr);

    font-weight: 500;
}

.detail-pair {
    display: flex;
    line-height: 1.5rem;
    // justify-content: space-around;

    dt {
        position: relative;
        /* display: inline-block; */
        width: 37%;
        /* position: relative; */
        // padding-right: 10px;
    }
    dt:after {
        content: ":";
        position: absolute;
        right: 10px;
    }
    span {
        color: red;
        font-weight: bold;
    }
}

.reqeust-menu-items {
    // position: absolute;
    display: flex;
    // color: blue;
    border: none;
    background: none;
    right: 1em;
    top: 0em;
    .view-po-link-fin-req {
        color: blue;
        border: none;
        background: none;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
.approval-bottom-btns-container {
    display: "flex";
    justify-content: "space-between";
    width: "100%";
    align-items: center;
}

.req-card-approve-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.req-card-update-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.request-history-item {
    display: flex;
    width: 96%;
    flex-direction: column;

    margin: 2em 0.5em;
    .request-history-item-details {
        border: "1px solid";
        border-radius: 5px;
        width: 40%;
        text-align: center;
        padding: 6px;
        p {
            padding: 3px;
        }
    }
}
.same-user {
    align-items: flex-end;
}
.diff-user {
    align-items: flex-start;
}
.proceedBtn {
    background-color: var(--staart-yellow);
}
.vendor-nosign-btns-container {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20%;
    justify-content: space-around;
}
.vendor-nosign-Btn {
    font-size: medium;
    padding: 10px;
    font-weight: 700;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.fin-req-comment-decline-body {
    display: flex;

    flex-direction: column;
    align-items: center;

    align-content: stretch;

    margin: 1.4em;
}
.fin-req-selected-items-details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 0.4em 0.4em;
    padding: 10px 5px;
    //  background-color: var(--staart-yellow);
    background-color: var(--staart-yellow);
    // font-weight: 800;
    border-radius: 10px;
}

.req-card-commentDetailsContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    // margin: 0 1em;
}

.highlightdiv {
    animation: hightlightitemdiv 5s 1;
}
@keyframes hightlightitemdiv {
    from {
        background-color: rgb(255, 211, 77);
        border-color: red;
    }
    to {
        // border
        background-color: "";
    }
}

.requests-filtersContainer {
    display: flex;
    justify-content: space-between;
    width: 95%;
    gap: 5%;
    margin: auto;
    align-items: center;
    > .status-dateFilters {
        display: flex;
        width: 60%;
        justify-content: space-around;
        align-items: center;
    }
}
@media screen and (max-width: 1250px) {
    .requests-filtersContainer {
        // display: flex;
        flex-direction: column;
        > .status-dateFilters {
            width: 100%;
        }
    }

    .checkbox-container {
        align-self: flex-start;
        margin: 5px 5px;
    }
    // .card-container {
    //     .req-card-mainDetailsContainer {
    //         flex-direction: column;
    //         .details-left-pan {
    //             width: 100%;
    //         }
    //         .details-right-pan {
    //             width: 100%;
    //         }
    //     }
    // }
}

@media screen and (max-width: 1150px) {
    .req-card-mainDetailsContainer {
        // display: grid;
        grid-template-columns: 1fr 0.2fr;
        // justify-items: center;
        // align-items: center;
        // .details-left-pan {
        //     width: 100%;
        // }
        // .details-right-pan {
        //     width: 100%;
        // }
    }

    // .approval-bottom-btns-container {
    //     display: flex;
    //     gap: 5px;
    //     justify-content: space-between;
    //     width: 100%;
    //     flex-direction: column;
    // }

    .checkbox-container {
        grid-row-start: 1;
        align-self: flex-start;
        margin: 5px 5px;

        #req-card-checkbox {
            height: 15px;
            width: 15px;
        }
    }
    .req-card-toggle {
        grid-row-start: 2;
    }
    .req-card-visibleContainer {
        //     gap: 10%;

        //     background-color: #fff8e1;
        //     .details-left-pan {
        //         width: 40%;
        //     }
        //     .details-right-pan {
        //         width: 40%;
        //     }
        grid-row-start: 2;
        grid-template-columns: 0.5fr auto;
        width: 100%;

        .req-card-header {
            grid-template-rows: auto;
            /* grid-template-columns: minmax(0px, 4fr) minmax(0px, 4fr) minmax(0px, 4.5fr) minmax(0px, 3fr) minmax(0px, 3fr) minmax(0px, 2.5fr); */
            row-gap: 5px;
            grid-template-columns: auto;
        }

        .req-card-mainDetails {
            p::before {
                content: ":";
                color: black;
                margin-right: 0.3em;
            }
            grid-template-rows: auto;
            /* grid-template-columns: minmax(0px, 4fr) minmax(0px, 4fr) minmax(0px, 4.5fr) minmax(0px, 3fr) minmax(0px, 3fr) minmax(0px, 2.5fr); */
            row-gap: 5px;
            grid-template-columns: auto;
        }
    }
    .mainExtraDetailsDiv {
        grid-column-start: 1;
    }
    .req-card-extraDetailsContainer {
        grid-template-columns: 1fr 0.2fr;

        // grid-template-columns: auto auto;
    }

    .reqeust-menu-items {
        grid-row-start: 3;
    }

    .req-card-main-actionContainer {
        grid-row-start: 3;
    }

    .req-card-update-btn {
        // grid-row-start: 2;
        width: 50%;
        margin-left: 4em;
    }
    .req-card-approve-btn {
        // grid-row-start: 2;
        width: 50%;
        margin-left: 4em;
    }
    .req-card-action-btns-container {
    }
}
