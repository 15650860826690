.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.modal-header {
  background: #fdd34d;
  padding: 16px 20px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.modal-header h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.close-button {
  padding: 6px !important;
}

.modal-body {
  padding: 16px;
}

.maintenance-section {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 16px;
}

.maintenance-summary {
  padding: 12px 16px;
  background: #fff;
  border-bottom: 1px solid #eee;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.summary-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.total-cost {
  background: #fdd34d;
  color: #333;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

.summary-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.summary-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.summary-item label {
  color: #666;
  font-size: 12px;
  font-weight: 500;
}

.summary-item span {
  font-size: 13px;
  font-weight: 500;
  color: #333;
}

.service-history {
  padding: 12px 16px;
}

.service-history h4 {
  color: #333;
  margin: 0 0 12px 0;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.service-history h4::after {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.maintenance-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.maintenance-table th {
  background: #fff;
  color: #666;
  font-weight: 600;
  text-align: left;
  padding: 8px;
  border-bottom: 2px solid #fdd34d;
}

.maintenance-table td {
  padding: 8px;
  border-bottom: 1px solid #eee;
  color: #333;
}

.maintenance-table tr:last-child td {
  border-bottom: none;
}

.maintenance-table tbody tr:hover {
  background: #fff9e6;
}

/* Custom scrollbar for modal */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #fdd34d;
  border-radius: 4px;
}

.maintenance-table tbody tr td:nth-child(4) {
  font-weight: 600;
}
.breakdown-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.breakdown-item {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.breakdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #f8f8f8;
}

.breakdown-cost {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.breakdown-details {
  padding: 16px;
}

.breakdown-info .reason {
  margin: 0 0 12px 0;
  font-size: 14px;
  color: #333;
}

.timestamps {
  display: flex;
  gap: 16px;
  font-size: 12px;
  color: #666;
}

.breakdown-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin-top: 12px;
}

.breakdown-images img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}
.breakdown-table-container {
  overflow-x: auto;
}

.breakdown-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
  font-size: 13px;
}

.breakdown-table th {
  background: #f8f8f8;
  color: #333;
  font-weight: 600;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #fdd34d;
  white-space: nowrap;
}

.breakdown-table td {
  padding: 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

.breakdown-table tr:hover {
  background-color: #fff9e6;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
}

.image-thumbnails {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.image-thumbnail-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.image-thumbnail-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
}

.image-thumbnail-wrapper:hover img {
  transform: scale(1.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .breakdown-table {
    font-size: 12px;
  }

  .breakdown-table td,
  .breakdown-table th {
    padding: 8px;
  }
}
/* Description cell styling */
.description-cell {
  max-width: 300px; /* Adjust based on your needs */
}

.description-content {
  max-height: 100px;
  overflow-y: auto;
  padding-right: 10px;
  white-space: normal !important;
  word-break: break-word;
}

/* Custom scrollbar for description */
.description-content::-webkit-scrollbar {
  width: 6px;
}

.description-content::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.description-content::-webkit-scrollbar-thumb {
  background: #fdd34d;
  border-radius: 3px;
}

/* Image count badge */
.image-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #fdd34d;
  color: #333;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
}

/* Image Preview Modal */
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.image-preview-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}

.preview-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;
}
.modal-content-wrapper {
  padding: 16px;
}

.preview-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.image-main-preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  position: relative;
}

.image-main-preview img {
  max-width: 100%;
  max-height: calc(100vh - 200px);
  object-fit: contain;
}

.image-thumbnails-preview {
  display: flex;
  gap: 10px;
  padding: 20px;
  overflow-x: auto;
  background: white;
  border-top: 1px solid #eee;
  height: 120px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  flex-shrink: 0;
  transition: all 0.2s ease;
}

.modal-content-wrapper {
  padding: 16px;
}

.description-cost-section {
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
}

.description-section,
.cost-section {
  padding: 12px 16px;
}

.label {
  font-size: 12px;
  color: #666;
  display: block;
  margin-bottom: 4px;
}

.value {
  font-size: 14px;
  color: #333;
  margin: 0;
  font-weight: 500;
}

.main-image-section {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.main-image-section img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.thumbnails-section {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
}
.thumbnail.active {
  border-color: #fdd34d;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail:hover {
  transform: translateY(-2px);
}

/* Custom scrollbar for thumbnails */
.thumbnails-section::-webkit-scrollbar {
  height: 4px;
}

.thumbnails-section::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 2px;
}

.thumbnails-section::-webkit-scrollbar-thumb {
  background: #fdd34d;
  border-radius: 2px;
}

.nav-button {
  padding: 4px !important;
  color: #666 !important;
}

.nav-button:hover {
  color: #333 !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Animations */
.compact-preview-modal {
  animation: modalSlideIn 0.2s ease-out;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .compact-preview-modal {
    width: 95vw;
  }

  .main-image-section img {
    max-height: 250px;
  }

  .description-cost-section {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .thumbnail {
    width: 50px;
    height: 50px;
  }
}

/* Add these responsive styles for the image preview */
@media (max-width: 768px) {
  .image-preview-content {
    padding: 0;
  }

  .preview-header {
    padding: 8px 12px;
  }

  .preview-title {
    font-size: 14px;
  }

  .image-main-preview {
    padding: 12px;
  }

  .image-thumbnails-preview {
    padding: 12px;
    height: 100px;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
  }
}
.compact-preview-modal {
  background: white;
  border-radius: 8px;
  width: 600px;
  max-width: 90vw;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}
/* Add these animations for smooth transitions */
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.image-main-preview img {
  animation: imageLoad 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes imageLoad {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.image-counter {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}
/* Custom scrollbar for table container */
.breakdown-table-container::-webkit-scrollbar {
  height: 8px;
}

.breakdown-table-container::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.breakdown-table-container::-webkit-scrollbar-thumb {
  background: #fdd34d;
  border-radius: 4px;
}
.breakdown-images img:hover {
  transform: scale(1.05);
}
/* Add subtle animations */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  animation: modalFadeIn 0.2s ease-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .summary-details {
    grid-template-columns: repeat(2, 1fr);
  }

  .maintenance-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .summary-details {
    grid-template-columns: 1fr;
  }

  .modal-content {
    width: 95%;
  }
}
