#userNameForm {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  gap: 15%;
  flex-direction: column;
  // @media screen and (max-width: 480px) {
  //   width: 100%;
  //   height: 160vh;
  // }
}
#background-video {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.mainHeading {
  font-size: 100px;
  color: white;
  font-weight: bold;
  z-index: 1;
  margin-bottom: 16px;
}

.overlay-video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

// > div:nth-child(3) {
//   position: absolute;
//   top: 50px;
//   left: 50;
//   width: 100%;
//   max-height: 70vh;
// }

// .container {
//   margin-top: 30px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   @media screen and(max-width: 480px) {
//     max-height: 100vh;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// }
.formFeilds {
  position: relative;
  z-index: 100;
  width: 48%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 5px 5px;
  border-radius: 16px;

  .formFeild_leftside {
    div {
      width: 100%;
    }
    width: 50%;
    display: flex;
    gap: 13%;
    flex-direction: column;
    align-items: center;
    // background-color: var(--staart-yellow);
    border: 6px solid #fdd34d;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 30px 30px;
  }

  .formFeild_Rightside {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--staart-yellow);
    padding-top: 6px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

// #checkbox {
//   width: 15px;
//   height: 15px;
//   cursor: pointer;
//   vertical-align: bottom;
//   color: white;
//   background-color: rgba(0, 0, 0, 0.08);
// }
@media screen and (max-width: 1600px) {
  #userNameForm {
    gap: 5%;
  }
}
@media screen and (max-width: 1500px) {
  #userNameForm {
    gap: 5%;
  }
}
@media screen and (max-width: 1250px) {
  #userNameForm {
    gap: 5%;
  }
  .formFeilds {
    width: 65%;
  }
}
@media screen and (max-width: 950px) {
  #userNameForm {
    gap: 5%;
  }
  .formFeilds {
    width: 75%;
  }
}
@media screen and (max-width: 750px) {
  #userNameForm {
    gap: 15%;
  }
  .formFeilds {
    flex-direction: column;
    align-items: center;
    width: 75%;
    gap: 16em;
    box-shadow: none;
    .formFeild_leftside {
      width: 100%;
      border-radius: 16px;
    }
    .formFeild_Rightside {
      width: 100%;
      border-radius: 16px;
    }
  }
}

.newUserSignUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
}
