.projectRow {
  display: flex;
  flex-direction: column;
  border: 0.2px solid #c4c4c4;
  border-radius: 10px;

  .projectHeader {
    display: flex;
    flex-direction: row;
    font-size: small;
    font-weight: 600;
    height: 45px;
    align-items: center;
    justify-content: space-between;

    > :nth-child(n) {
      flex: 1;
      text-align: center;
    }

    &:hover {
      background-color: #ebecf0;
    }
  }

  .projectDetails {
    padding: 20px;
  }
}
