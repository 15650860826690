.slide-out-list {
  position: fixed;
  top: 50%;
  left: 50%; /* Start off-screen */
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid #4b4b4b;
  transform: translateY(-20%);
  transition: top 0.5s ease, left 0.5s ease;
}

.slide-out-list.visible {
  top: 25%;
  left: 110%; /* Position it outside the modal */
  width: 300px;
  height: 100%;
  /* overflow: auto; */
  z-index: 10000;
}

.slide-out-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slide-out-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}


