/* EntryLogs.css */
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f9fafb;
}

.entry-logs-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header-container {
  width: 100%;
  padding: 20px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  z-index: 100;
}

.header-with-back-button {
  display: flex;
  align-items: center;
}

.back-button {
  margin-right: 16px;
  color: #495057;
  border-radius: 50%;
}

.back-icon {
  font-size: 22px;
}

.header-text {
  flex: 1;
}

.date-selector-container {
  min-width: 360px;
}

.spinner-border {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.table-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 100px);
  width: 100vw;
  margin: 0;
  padding: 0;
}

.table-scroll-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.entry-logs-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.entry-logs-table th,
.entry-logs-table td {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
  height: 60px; /* Fixed height for all cells */
  box-sizing: border-box;
}

.entry-logs-table thead {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #ffffff;
}

.entry-logs-table th {
  background-color: #ffffff;
  color: #1f2937;
  font-weight: 600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Fix for project column header - this is crucial for fixing the scrolling issue */
.project-column {
  min-width: 250px;
  width: 250px;
  text-align: left;
  position: sticky;
  left: 0;
  z-index: 60;
  background-color: #ffffff !important;
  box-shadow: 4px 0 8px -4px rgba(0, 0, 0, 0.1);
}

/* This ensures the project column header stays fixed even when scrolling horizontally */
.entry-logs-table th.project-column {
  position: sticky;
  left: 0;
  z-index: 60; /* Higher z-index to appear above other headers */
}

.date-column {
  min-width: 140px;
  width: 140px;
}

.filler-column, .filler-cell {
  width: 100%;
  min-width: 50px;
  border-bottom: 1px solid #e5e7eb;
}

.date-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weekday {
  font-weight: 700;
  margin-bottom: 4px;
  color: #4A6CF7;
}

.full-date {
  font-size: 13px;
  color: #6b7280;
}

.project-name {
  min-width: 250px;
  width: 250px;
  text-align: left;
  font-weight: 600;
  position: sticky;
  left: 0;
  z-index: 40;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 4px 0 8px -4px rgba(0, 0, 0, 0.1);
  height: 60px;
  box-sizing: border-box;
}

/* Ensure project name cell background matches row background */
.even-row .project-name {
  background-color: #ffffff;
}

.odd-row .project-name {
  background-color: #f9fafb;
}

/* Fix to ensure the top-left corner cell gets proper styling when table is scrolled */
.project-column:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ffffff;
}

/* Make sure all rows have consistent height */
.entry-logs-table tr {
  height: 60px;
}

.entry-cell {
  vertical-align: middle;
  height: 60px;
}

.entry-cell:hover .entry-value {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.entry-value {
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  transition: all 0.2s ease;
}

.even-row {
  background-color: #ffffff;
}

.odd-row {
  background-color: #f9fafb;
}

/* Modern scrollbar styling */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f1f1;
}

@media (max-width: 768px) {
  .entry-logs-container {
    overflow-y: auto;
    height: auto;
  }
  
  .header-container {
    padding: 16px;
  }
  
  .table-container {
    height: auto;
    overflow: visible;
  }
  
  .table-scroll-container {
    overflow-x: auto;
    overflow-y: visible;
  }
  
  .entry-logs-table {
    font-size: 13px;
  }
  
  .entry-logs-table th,
  .entry-logs-table td {
    padding: 12px 8px;
  }
  
  .project-column {
    min-width: 160px;
    width: 160px;
  }
  
  .project-name {
    min-width: 160px;
    width: 160px;
  }
  
  .date-column {
    min-width: 100px;
    width: 100px;
  }
}