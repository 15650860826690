.order-heading-container {
    background-color: #fff8e1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
}
.order-details {
    display: flex;
    width: 88%;
    gap: 10px;
    flex-direction: column;

    .order-details-vendor {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .order-details-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            border-style: solid;
            border-width: 1px;
            border-radius: 50%;
            border-color: #fdd34d;
        }
    }
    .order-heading-dates {
        display: flex;
        gap: 30px;
    }
}
.order-heading-amount {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;
}
.order-heading-util {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-no-milestones {
    display: flex;
    flex-direction: column;
    align-items: center;
    .order-no-milestones-fetchmilestones-btn {
        font-size: 1rem;
        border: none;
        background: #ffd34d;
        color: black;
        padding: 0.75em;
        font-weight: 600;
        border-radius: 5px;
        &:hover {
            cursor: pointer;
        }
    }
}

.milestone-pay-btn {
    display: inline-block;
    padding: 7px 7px;
    font-size: 14px;
    font-weight: bold;
    // text-transform: uppercase;
    border: none;
    border-radius: 30px;
    background-color: #5cb85c;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.milestone-pay-btn:hover {
    background-color: #4cae4c;
}

.milestone-pay-btn:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(92, 184, 92, 0.3);
}

.milestone-pay-btn:disabled {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
}

.milestone-request-btn {
    display: inline-block;
    padding: 7px 7px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.milestone-request-btn:hover {
    background-color: #ddd;
}

.milestone-request-btn:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(40, 40, 226, 0.3);
}

.milestone-request-btn:disabled {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
}
