.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .modal-content {
    width: 22%;
    height: 100%;
    background: #F9F9F9;
    padding: 20px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
  }
  
  .modal-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .modal-list-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-list-item:last-child {
    border-bottom: none;
  }
  