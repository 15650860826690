/* DropdownComponent.css */
.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 250px; /* Adjust as needed */
  }
  
  .dropdown-label {
    font-size: 14px;
    color: #6e6e6e;
    font-weight: 500;
  }
  
  .dropdown {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    appearance: none;
    background: white url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="gray" d="M2 0L0 2h4z"/></svg>') no-repeat right 12px center;
    background-size: 12px;
    outline: none;
    cursor: pointer;
  }
  
  .dropdown:hover {
    border-color: #999;
  }
  
  .dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .dropdown option {
    font-size: 16px;
    padding: 8px;
  }
  