.chat-modal-container {
    height: 100%;
    .chat-modal-header {
    }
    .chat-modal-body {
        height: 100%;
        .body-chat-container {
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            /* justify-content: space-between; */
            /* align-items: center; */
            grid-gap: 0.4em;
            &::-webkit-scrollbar-track {
                background: transparant;
            }
            &::-webkit-scrollbar-thumb {
                background: transparent;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #969696;
            }

            .chat-bubble {
                display: flex;
                flex-direction: column;
            }
            .same-user {
                // background: #394867;
                color: white;

                align-items: end;
            }
            .diff-user {
                color: black;

                align-items: start;
            }
        }
    }
    .chat-modal-bottom-container {
        display: flex;
    }
}
.siteEnggChatContainer {
    // position: "relative";
    // top: "50%";
    // left: "50%";
    // // transform: "translate(-50%, -50%)",;
    // transform: translate(-50%, -50%);
    // overflow: "auto";
    // // bgcolor: "background.paper",
    // border-radius: "10px";
    // box-shadow: 24;
    // padding: 4;
    // width: "35%";
    // height: "90%";
}
.messageImage {
    min-width: 250px;
    height: 300px;
    object-fit: contain;
}
