.assign-projects-modal{
    display: flex;
    margin: 4em 0em;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    h3{
        position: absolute;
        top: 0;
        margin-top: 0.5em;
    }
    .user-details{
        display: flex;
        flex-direction: column;
        gap: 0.75em;
        .user-details-heading{
            font-weight: bold;
        }
        .user-details-contact{
            height: 2em;
            font-size: 1rem;
            padding: 3px;
        }
    }

    .assign-modal-update-btn{
        padding: 0.5em;
        width: 120px;
        height: 30px;
        background-color: #FFD34E;
        color: black;
        border: none;
        border-radius: 5px ;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        &:hover{
          cursor: pointer;
        }
    }

    
}