.horizontal-form {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.horizontal-form.header {
  background-color: #f0f0f0;
  font-weight: bold;
  box-shadow: none;
}

.form-input {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  transition: border-color 0.3s ease;
}

.date-input {
  flex: 0 0 auto;
  width: 180px;
  padding-right: 30px;
}
.percentage-icon {
  flex: 0 0 auto;
  width: 150px;
  padding-right: 30px;
}
.invoice-button {
  flex: 0 0 auto;
  width: 90px;
  padding-right: 30px;
}

.date-input-name {
  flex: 0 0 auto;
  width: 300px;
  padding-right: 30px;
}

.form-input:focus {
  outline: none;
  border-color: #4caf50;
}

.add-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 6px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #45a049;
}

.sub-item {
  background-color: #f0f4f8;
  padding: 10px 15px;
}

.item-number {
  width: 30px;
  text-align: center;
  font-weight: 700;
}

.status-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.status-icon {
  font-size: 18px;
}

.status-icon.completed {
  color: #4caf50;
}

.status-icon.not-completed {
  color: #f44336;
}

@media (max-width: 768px) {
  .horizontal-form {
    flex-direction: column;
    align-items: stretch;
  }

  .form-input,
  .add-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
