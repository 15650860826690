#project-analytics {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 250px 200px 1fr 300px 1fr;
  grid-template-rows: 60px 1fr 1fr;
  background-color: rgb(243, 243, 243);
  #project-analytics-header {
    grid-column: 1/6;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: white;
  }
  > :nth-child(1) {
    padding: 0px 20px;
  }
  > :nth-last-child(-n + 9) {
    margin: 10px;
    border-radius: 10px;
  }
  > :nth-child(2) {
    padding: 20px;
    display: grid;
    grid-template-columns: 30px 1fr;
    background-color: white;
    box-shadow: 0px 0px 3px grey;
    > :nth-child(1) {
      grid-column: 1/3;
      text-align: center;
      font-weight: 700;
    }
    > div {
      font-size: small;
    }
  }
  > :nth-child(3),
  > :nth-child(9) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      flex: 1;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 3px grey;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      > div {
        text-align: center;
        font-size: small;
      }
      > div:nth-child(1) {
        font-weight: 700;
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: #feb161;
        font-weight: 700;
        gap: 6px;
        > p {
          font-size: 45px;
        }
        > small {
          font-size: medium;
        }
      }
      > div:nth-child(3) {
        text-decoration: underline;
        color: blue;
      }
    }
  }
  > :nth-child(4) {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 3px grey;
    > div {
      text-align: center;
      font-size: small;
    }
    > div:nth-child(1) {
      font-weight: 700;
    }
    > :nth-child(2) {
      position: relative;
      left: -20px;
    }
  }
  > :nth-child(5) {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 3px grey;
    > div {
      text-align: center;
      font-size: small;
    }
    > div:nth-child(1) {
      font-weight: 700;
    }
  }
  > :nth-child(6) {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 3px grey;
    font-size: small;
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 20px;
    > :nth-child(1) {
      grid-column: 1/3;
      font-weight: 700;
      text-align: center;
    }
    > :nth-last-child(1) {
      grid-column: 1/3;
      text-align: center;
      color: blue;
      text-decoration: underline;
    }
    > :nth-child(3),
    :nth-child(5),
    :nth-child(7),
    :nth-child(9),
    :nth-child(11) {
      height: 20px;
      border: 0.5px solid #feb2618b;
      border-radius: 5px;
      display: flex;
      > div {
        background-color: #feb161;
        border-radius: 5px;
      }
    }
  }
  > :nth-child(7) {
    grid-column: 1/3;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 3px grey;
    font-size: small;
    gap: 20px;
    display: grid;
    grid-template-columns: 20px 1fr;
    > :nth-child(1) {
      font-weight: 700;
      text-align: center;
      grid-column: 1/3;
    }
    > :nth-child(2) {
      grid-column: 1/3;
      height: 30px;
      display: flex;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > :nth-child(3),
    > :nth-child(5),
    > :nth-child(7) {
      background-color: #feb161;
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
  }
  > :nth-child(8) {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 3px grey;
    > div {
      text-align: center;
      font-size: small;
    }
    > div:nth-child(1) {
      font-weight: 700;
    }
  }
  > :nth-child(9) {
    > div {
      box-shadow: none;
      background-color: rgb(224, 224, 224);
      color: rgb(194, 194, 194);
      text-decoration: solid;
      gap: 0px;
      div,
      p,
      small {
        color: rgb(194, 194, 194);
      }
    }
  }
  > :nth-child(10) {
    box-shadow: none;
    background-color: rgb(224, 224, 224);
    padding: 20px;
    font-size: small;
    gap: 20px;
    display: grid;
    grid-template-columns: 20px 1fr;
    > :nth-child(1) {
      font-weight: 700;
      text-align: center;
      grid-column: 1/3;
      color: rgb(194, 194, 194);
    }
    > :nth-child(2) {
      grid-column: 1/3;
      height: 30px;
      display: flex;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > :nth-child(3),
    > :nth-child(5) {
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
  }
}
