#searchBar {
  box-sizing: border-box;
  width: 400px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ebebeb;
  height: 40px;
  padding: 0px 10px;
  > :nth-child(1) {
    font-size: small;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
  }
  > :nth-child(2) {
    opacity: 0.8;
  }
}
