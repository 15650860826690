.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  height: 90vh;
  max-width: 90%; /* Adjust this value as needed */
  min-width: 90%; /* Remove min-width constraint */
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto; /* This ensures centering */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f5f5f5;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease, background 0.3s ease;
  z-index: 1;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-close:hover {
  color: #ff0000;  /* Change text color on hover */
  background: #e0e0e0;  /* Slightly darker background on hover */
  transform: scale(1.2);  /* Slightly enlarge the button on hover */
}

.modal-close:active {
  transform: scale(1.1);  /* Reduce the scale a bit when the button is active */
  background: #d0d0d0;  /* Darker background on active */
}


.modal-title {
  padding: 20px 30px;
  margin: 0;
  color: #333;
  font-size: 24px;
  border-bottom: 1px solid #eee;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 30px;
}

.modal-body h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  color: #4CAF50;
  font-size: 20px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 95vh;
  }

  .modal-title {
    font-size: 20px;
    padding: 15px 20px;
  }

  .modal-body {
    padding: 15px 20px;
  }

  .modal-body h3 {
    font-size: 18px;
  }
}