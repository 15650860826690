.search-container {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 100%;
  }
  
  #search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  #search-input:focus {
    border-color: rgba(0, 0, 0, 0.5);
  }
  