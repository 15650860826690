@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

#search {
  box-sizing: border-box;
  height: 50px;
  margin-bottom: 20px;
  overflow: visible;
  border-radius: 10px;
  border: 1px solid #3535354d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  > :nth-child(1) {
    margin: 10px;
    opacity: 0.2;
  }
  > :nth-child(2) {
    font-size: 20px;
    width: 400px;
    border: none;
    outline: none;
    background-color: white;
  }
}
