.chat-input-container {
  padding: 16px;
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.message-input {
  flex-grow: 1;
  min-height: 40px;
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 20px;
  font-size: 14px;
  resize: none;
}

.message-input::placeholder {
  color: #9ca3af;
}

.attach-button,
.send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s;
}

.attach-button:hover,
.send-button:hover {
  background-color: #d1d5db;
}

.attach-icon {
  font-size: 24px;
  color: #6b7280;
  transform: rotate(45deg);
}

.send-icon {
  font-size: 24px;
  color: #3b82f6;
}

.collapse-button {
  display: block;
  width: 100%;
  margin-top: 12px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #e5e7eb;
  color: #4b5563;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.collapse-button:hover {
  background-color: #d1d5db;
}
