#drawerTemplate1 {
  display: flex;
  flex-direction: row;
  width: 99vw;
  min-height: 100vh;
  height: 100%;
  #drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    background-color: #2f4858;
    padding: 30px 0px;
    gap: 30px;
    > :nth-child(n) {
      color: #fff8e1;
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
    > :nth-child(1) {
      margin-bottom: 40px;
      border: 3px solid #fff8e1;
      border-radius: 20px;
      font-size: x-small;
      font-weight: 700;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
