.button1 {
  background-color: #2f4858;
  color: #ffffff;
}

.invert1 {
  background-color: #ffffff;
  color: #2f4858;
  font-weight: 700;
  border: 1px solid #2f4858;
}
.commonBtn1 {
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px grey;
  font-size: small;
  padding: 0px 17px;
  &:hover {
    box-shadow: none;
    cursor: pointer;
  }
}
