#indentSection {
  margin: 40px;

  > :nth-child(2) {
    margin-top: -80px;
    padding: 10px 10px 10px 30px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    -ms-overflow-style: none;
    gap: 20px;

    > :nth-child(n) {
      border: 1px solid rgb(255, 211, 77);
      padding: 10px;
      height: fit-content;
      border-radius: 30px;
      color: black;
      text-align: center;
      min-width: 180px;
      cursor: pointer;

      &:hover {
        border: 1px solid rgb(255, 211, 77);
        padding: 10px;
        height: fit-content;
        border-radius: 30px;
        background-color: rgb(255, 211, 77);
        color: white;
      }
    }

    > .active {
      border: 1px solid rgb(255, 211, 77);
      padding: 10px;
      height: fit-content;
      border-radius: 30px;
      background-color: rgb(255, 211, 77);
      color: black;
    }
  }

  > :nth-child(2)::-webkit-scrollbar {
    display: none;
  }

  > :nth-child(3) {
    overflow-y: scroll;
    padding-top: 20px;
    -ms-overflow-style: none;
  }

  > :nth-child(3)::-webkit-scrollbar {
    display: none;
  }
}

.menuItem {
  color: rgb(27, 27, 27);
  font-weight: 500;

  &:hover {
    color: rgb(198, 198, 198);
    text-decoration: underline;
  }
}

.highlightdiv{
  animation: hightlightitemdiv 10s 1;
}
@keyframes hightlightitemdiv {
from {background-color: rgb(255, 211, 77) }
to {background-color: '';}
}

.request-button {
  margin-left: -1.2em;
  display: inline-block;
  padding: 7px 7px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.request-button:hover {
  background-color: #ddd;
}

.request-button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px rgba(40, 40, 226, 0.3);
}

.request-button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}
