.input {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.wrapInput {
  padding: 0px 4px;
}

// .input__label{
//     color: lightgray !important;
//     margin-left: 8px;
//     border-bottom: none !important;
// }
