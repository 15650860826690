.additional-info-wrapper {
    width: 100%;
    background-color: #f8f9fa;
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 12px 24px;
    width: 100%;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .info-label {
    font-weight: 700;
    color: #555;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }
  
  .info-value {
    color: #333;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 0;
  }
  
  /* Optional: Add some hover effect for better interaction */
  .info-item:hover {
    background-color: #f1f1f1;
    border-radius: 4px;
    padding: 0 4px;
  }