.button {
  border: 2px solid white;

  background-color: var(--staart-yellow);
}

.invert {
  border: 1px solid #fdd34d;

  background-color: white;
}
.commonBtn {
  width: 100%;
  // max-width: 100%;
  display: flex;
  flex: 0.75;
  flex-direction: column;
  justify-content: center;
  color: #353535;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 3px;
  max-height: 50px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

.commonBtn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
