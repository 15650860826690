#tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // header all tabs
  > :nth-child(1) {
    margin-left: 2em;
    display: flex;
    flex-direction: row;
    gap: 5%;
    // height: 70px;
    > div {
      // flex: 1;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // padding: 12px 0px;
      font-size: 22px;
      &:hover {
        // background-color: #f4f4f4;
        cursor: pointer;
        // height: 70px;
      }
    }
    .activeTabHighlight {
      font-weight: 600;
      // background-color: #f7cf56;
      border-bottom: solid 4px #ffd34d;
      cursor: pointer;
      // height: 70px;
    }
  }
  // childrens
  > :nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
