#indentSection {
  margin: 40px;

  > :nth-child(2) {
    margin-top: -80px;
    padding: 10px 10px 10px 30px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    -ms-overflow-style: none;
    gap: 20px;

    > :nth-child(n) {
      border: 1px solid rgb(255, 211, 77);
      padding: 10px;
      height: fit-content;
      border-radius: 30px;
      color: black;
      text-align: center;
      min-width: 180px;
      cursor: pointer;

      &:hover {
        border: 1px solid rgb(255, 211, 77);
        padding: 10px;
        height: fit-content;
        border-radius: 30px;
        background-color: rgb(255, 211, 77);
        color: white;
      }
    }

    > .active {
      border: 1px solid rgb(255, 211, 77);
      padding: 10px;
      height: fit-content;
      border-radius: 30px;
      background-color: rgb(255, 211, 77);
      color: black;
    }
  }

  > :nth-child(2)::-webkit-scrollbar {
    display: none;
  }

  > :nth-child(3) {
    overflow-y: scroll;
    padding-top: 20px;
    -ms-overflow-style: none;
  }

  > :nth-child(3)::-webkit-scrollbar {
    display: none;
  }
}

.menuItem {
  color: rgb(27, 27, 27);
  font-weight: 500;

  &:hover {
    color: rgb(198, 198, 198);
    text-decoration: underline;
  }
}

// .highlightdiv{
//   animation: hightlightitemdiv 3s 1;
// }
// @keyframes hightlightitemdiv {
// from {background-color: rgb(255, 211, 77) }
// to {background-color: '';}
// }
.approval-bottom-container {
  background: #fff8e1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 20px;

  button {
    &:hover {
      cursor: pointer;
    }
  }
  .approval-edit-btn {
    padding-left: 30px;
    border: none;
    background: none;
    text-decoration: underline;
    font-weight: 600;
    // font-size: 18px;
    // line-height: 22px;
    // letter-spacing: 0.0015em;
    text-transform: capitalize;
    color: #4685d4;
  }
  .approval-bottom-buttons {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
    gap: 10%;

    .approval-chat-btn {
      background: white;
      border: 1px solid;
      font-weight: 900;
      border-color: #616161;
      color: #616161;
      padding: 10px 20px;
      border-radius: 10px;
    }
    .approval-onhold-btn {
      background: white;
      border: 1px solid;
      font-weight: 900;
      padding: 10px;
      border-color: #616161;
      color: #616161;
      padding: 10px 20px;
      border-radius: 10px;
    }

    .approval-decline-btn {
      border: 1px solid #e74646;
      background: none;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      border-radius: 10px;
      padding: 10px 20px;
      color: #e74646;

      font-weight: 500;
    }
    .approval-approve-btn {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      background: #56b04e;
      border: none;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 17px;
      color: white;
      border-radius: 10px;
    }
  }
}
.no-orders {
  position: absolute;
  top: 50%;
  left: 40%;
}
.comment-decline-header {
  display: flex;
  justify-content: center;
  margin: 0.75em 0;
}
.comment-decline-body {
  display: flex;
  margin: 1.4em;
}
.comment-decline-bottom {
  display: flex;
  justify-content: center;
}
.approval_container {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.request-history-modal-container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  .request-history-header {
    color: #5570f1;
    text-align: center;
  }
  .request-history-item {
    display: flex;
    width: 100%;
    flex-direction: column;

    margin: 2em 0.5em;
    .request-history-item-details {
      border: "1px solid";
      border-radius: 5px;
      width: 40%;
      text-align: center;
      padding: 6px;
      p {
        padding: 3px;
      }
    }
  }
  .same-user {
    align-items: flex-end;
  }
  .diff-user {
    align-items: flex-start;
  }
}
