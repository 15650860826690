.brands-edit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .brands-edit-heading {
        text-align: center;
    }
    .brands-edit-brandslist {
        padding: 0.75em 0px;
    }
    .brands-edit-brand-input {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 2em;
        input {
            width: 80%;
            font-size: 1em;
        }
        button {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 3px;
            color: #353535;
            font-weight: bold;
            background-color: var(--staart-yellow);
        }
    }

    .brands-edit-bottom {
        margin-top: 1em;
        .brands-update-button {
            display: flex;
            font-weight: 700;
            background-color: rgb(253, 211, 77);
            width: 100%;
            justify-content: center;
            padding: 14px;
            border-radius: 5px;
            cursor: pointer;
            border: none;
        }
    }
}
