.vehicles-container {
  padding: 20px;
  background-color: #fff;
  height: 100vh; /* Change from min-height: 100vh */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box; /* Include padding in height calculation */
}
/* Add this new CSS */
.filters-container {
  position: relative;
  margin-left: 16px;
}

.filter-trigger {
  background: #fdd34d;
  border: 1px solid #e0e0e0;
  padding: 10px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  align-self: flex-start;
}

.filter-trigger:hover {
  border-color: #fdd34d;
  background: #fff;
}

.filter-trigger.active {
  background: #fdd34d15;
  border-color: #fdd34d;
  padding: 8px 16px;
  border-radius: 8px;
}

.filter-modal {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  width: 320px;
  z-index: 1000;
  overflow: hidden;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.filter-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.filter-header button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.filter-header button:hover {
  background: #f5f5f5;
}

.filter-content {
  padding: 16px;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section:last-child {
  margin-bottom: 0;
}

.filter-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.filter-section-title {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.select-all-btn {
  background: none;
  border: none;
  color: #1976d2;
  font-size: 13px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.select-all-btn:hover {
  background: #f5f5f5;
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 8px;
}

.filter-options::-webkit-scrollbar {
  width: 6px;
}

.filter-options::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 3px;
}

.filter-options::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 3px;
}

.filter-option {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  transition: all 0.2s;
}

.filter-option:hover {
  background: #f5f5f5;
}

.filter-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}

.filter-checkbox:checked {
  background: #fdd34d;
  border-color: #fdd34d;
}

.filter-checkbox:checked::after {
  content: "✓";
  position: absolute;
  color: white;
  font-size: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.active-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
}

.filter-tag {
  background: #fdd34d15;
  border: 1px solid #fdd34d;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #333;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.filter-tag button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-tag button:hover {
  color: #333;
}
.header-tabs {
  display: flex;
  gap: 2px;
  background: #f5f5f5;
  padding: 4px;
  border-radius: 24px;
  width: fit-content; /* Add this to make container wrap content */
}

.controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
  flex-wrap: wrap;
}

.search-wrapper {
  flex: 1;
}

.search-wrapper input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.search-wrapper input:focus {
  outline: none;
  border-color: #fdd34d;
}

.filters {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.time-filter {
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #fdd34d;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.time-filter:hover {
  background-color: #ffe44d;
}

.date-pickers {
  display: flex;
  gap: 12px;
}

.modern-date-picker {
  background-color: #fdd34d;
  border-radius: 8px;
}

.table-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: white;
  overflow: hidden; /* Contain table shadow */
}

.table-scroll-container {
  overflow-x: auto;
  border-radius: 8px;
  height: 80vh;
}
.table-scroll-container {
  transition: all 0.3s ease;
}

.add-vehicle-button {
  position: relative;
  overflow: hidden;
}

.add-vehicle-button:hover {
  background-color: #e5b925 !important;
}

.add-vehicle-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-vehicle-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.add-vehicle-button:hover::before {
  left: 100%;
}

.add-vehicle-button svg {
  transition: transform 0.3s ease;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
}

th,
.tdClass {
  padding: 12px 16px;
  text-align: right;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  max-width: 500px; /* Set your desired maximum width */
  width: 200px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break words to prevent overflow */
  overflow-wrap: break-word; /* Modern browsers */
  vertical-align: top; /* Align content to top when row height increases */
}
td {
  padding: 12px 16px;
  text-align: right;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
  font-size: 14px;
}

th {
  background-color: #f8f8f8;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
  border-color: #dddddd;
  white-space: nowrap;
  text-align: right;
}

.group-header {
  background-color: #fde8a5;
  text-align: center;
  font-weight: 600;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  position: sticky;
  z-index: 1;
}

tr:nth-child(even) {
  background-color: #fafafa;
}

tr:hover {
  background-color: #f5f5f5;
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  .controls-section {
    flex-direction: column;
    align-items: stretch;
  }

  .search-wrapper {
    max-width: 100%;
  }

  .filters {
    flex-direction: column;
  }

  .date-pickers {
    flex-direction: column;
  }

  .tab {
    padding: 8px 16px;
    font-size: 13px;
  }
}

/* Custom scrollbar styles */
.table-scroll-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background: #fdd34d;
  border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #ffe44d;
}

/* Table column specific styles */
table td.status-cell {
  text-align: center;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.active {
  background-color: #e6f4ea;
  color: #1e7e34;
}

.status-badge.inactive {
  background-color: #feeced;
  color: #dc3545;
}

/* Additional modern touches */
.MuiDatePicker-root {
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-root {
  border-radius: 8px !important;
}

/* Loading state */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #fdd34d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.export-button {
  transition: all 0.3s;
}

.export-button:hover {
  background-color: #e5b925 !important;
}

.export-button:active {
  transform: scale(0.98);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}