.activeText {
  font-weight: bold;
}

.mainTabs {
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  > div {
    > div:nth-child(1) {
      padding: 10px 45px;
      font-size: small;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      > :nth-child(1) {
        font-weight: 700;
      }
    }
    .boqTab {
      background-color: #f6f6f6;
      color: #353535;
      &:hover {
        opacity: 0.6;
      }
    }
    .indentTab {
      background-color: var(--staart-yellow);
      color: #353535;
      &:hover {
        opacity: 0.6;
      }
    }
    .active {
      height:3px;
      background-color: rgb(255, 211, 77);
      margin: 5px 0px;
      border-radius: 2px;
    }
  }
}

.activeTab {
  height: 5px;
  background-color: rgb(255, 211, 77);
  margin: 5px 0px;
  border-radius: 2px;
}

.boqTab {
  background-color: #f6f6f6;
  color: #353535;
  &:hover {
    opacity: 0.6;
  }
}

.indentTab {
  background-color: var(--staart-yellow);
  color: #353535;
  &:hover {
    opacity: 0.6;
  }
}

.active {
  height: 5px;
  background-color: rgb(255, 211, 77);
  margin: 5px 0px;
  border-radius: 2px;
}

mark {
  background: none;
  font-weight: bold;
}

.highlightdiv {
  animation: hightlightitemdiv 3s 1;
}
@keyframes hightlightitemdiv {
  from {
    background-color: rgb(255, 211, 77);
  }
  to {
    background-color: "";
  }
}
