.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.3;
  gap: 10px;
  > div {
    flex: 1;
    height: 2px;
    border-radius: 4px;
    background-color: grey;
  }
}
