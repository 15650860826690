.filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px 20px;
  }
  
  .filter-bar__dropdown {
    position: relative;
    display: inline-block;
  }
  
  .filter-bar__dropdown-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .filter-bar__dropdown-button svg {
    margin-left: 8px;
  }
  
  .filter-bar__dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .filter-bar__dropdown-content a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .filter-bar__dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .filter-bar__dropdown:hover .filter-bar__dropdown-content {
    display: block;
  }
  
  .filter-bar__search-input {
    width: 300px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }