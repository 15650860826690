#notification {
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  z-index: 1001;
  #notification_icon {
    z-index: 1004;
    position: absolute;
    right: 0px;
    margin-top: 50px;
    background-color: #2f4858;
    height: 42px;
    width: 40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #notification_container {
    background-color: white;
    border-left: 1px solid rgb(218, 218, 218);
    transition: width 150ms;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    > :nth-child(2) {
      font-weight: 600;
    }
    > :nth-child(2) {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid grey;
      padding-bottom: 10px;
      > :nth-child(n) {
        display: flex;
        gap: 5px;
        cursor: pointer;
        color: rgb(186, 186, 186);
        font-size: small;
        > :nth-child(2) {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background-color: rgb(186, 186, 186);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 10px;
        }
      }
      .active {
        color: rgb(39, 39, 39);
        font-weight: 700;
        > :nth-child(2) {
          background-color: #2f4858;
        }
      }
    }
    > :nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      > .title {
        font-size: small;
        font-weight: 600;
      }
      > .notification_card {
        border-radius: 10px;
        border: 1px solid grey;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 12px;
        font-size: 11px;
        &:hover {
          cursor: pointer;
        }
        > :nth-child(1) {
          display: flex;
          gap: 5px;
          align-items: center;
          > :nth-child(3) {
            font-weight: 600;
          }
        }
        > :nth-child(3) {
          display: flex;
          gap: 10px;
          > :nth-child(1) {
            margin-right: auto;
          }
          > :nth-child(2) {
            font-weight: 600;
            color: green;
            cursor: pointer;
          }
          > :nth-child(3) {
            font-weight: 600;
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

#displayNone {
  display: none;
}
