.mark-as-paid-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mark-as-paid-btn:hover {
    background-color: #218838;
  }
  
  .mark-as-paid-btn:active {
    transform: scale(0.95);
  }
  
  .mark-as-paid-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(72, 180, 97, 0.5);
  }
  