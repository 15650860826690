.newOrderCard {
  width: 260px;
  // height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 4px 20px 3px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
}
