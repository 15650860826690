.brands-container{
    position: absolute;
    width: 100%;
    // top: 7em;
    z-index: 1000;
    margin: 0px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: #eee;
    border-radius: 5px;
    max-height: 200px;
    list-style: none;
    margin: 0;
    padding: 8px;
    max-height: 40vh;
    overflow: auto;
    .item-of-brands{
        width: 100%;
        font-weight: 400;
          font-size: 1rem;
          line-height: 1;
          letter-spacing: 0.00938em;
          padding-top: 6px;
          &:hover{
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.04);

          }
    }

}