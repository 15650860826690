.vendor-statement-order {
    border: 1px solid #6770bc;
    width: 100%;
    margin: 2em 0em;
    position: relative;
    .vendor-statement-order-head-details {
        // table {
        //     width: 100%;
        //     border-collapse: collapse;
        //     border: 1px solid #ddd;
        //     td,
        //     tr,
        //     th,
        //     tbody {
        //         text-align: center;
        //         border-collapse: collapse;
        //         border: 1px solid #ddd;
        //     }
        // }
        // tr {
        //     th:nth-child(1) {
        //         width: 25%;
        //     }
        //     th:nth-child(2) {
        //         width: 25%;
        //     }
        //     th:nth-child(3) {
        //         width: 25%;
        //     }
        //     th:nth-child(4) {
        //         width: 15%;
        //     }
        //     th:nth-child(5) {
        //         width: 10%;
        //     }
        // }

        font-weight: 700;
        display: flex;
        justify-content: space-between;
        margin: 1em 0em;
        .order-head-details-left-container,
        .order-head-details-right-container {
            width: 40%;
            display: table;
            p {
                display: table-row;
                b {
                    display: table-cell;
                }
            }
        }
    }
    .vendor-statement-order-payments {
        table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #ddd;
            //   background-color: #f2f2f2;
            // >:nth-child(2n+1){
            //     background-color: #f2f2f2;

            // }

            td,
            tr,
            th,
            tbody {
                text-align: center;
                border-collapse: collapse;
                border: 1px solid #ddd;
                //   background-color: #f2f2f2;
            }
        }
        tr:nth-child(2n) {
            background: #f2f2f2;
        }
        tr {
            td:nth-child(3) {
                width: 55%;
            }
            td:nth-child(2) {
                width: 20%;
            }
            td:nth-child(1) {
                width: 15%;
            }
            td:nth-child(4) {
                width: 10%;
            }
        }
    }
}

.vendor-statement-container {
}

.vendor-statement-details-container {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .vendor-statement-vendor-details {
    }
    .vendor-statement-project-acc-summary {
        width: 35%;
        font-weight: bold;
        margin-bottom: 2em;
        .project-acc-summary-header {
            text-align: center;
        }
        .project-acc-summary-body {
            // display: grid;
            // grid-template-columns: 2fr 2fr;
            // .project-acc-summary-text p:after {
            //     content: ":";
            // }
        }
    }
}
.detail-pair {
    display: flex;
    line-height: 1.5rem;
    // justify-content: space-around;

    dt {
        position: relative;
        /* display: inline-block; */
        width: 55%;
        /* position: relative; */
        // padding-right: 10px;
    }
    dt:after {
        content: ":";
        position: absolute;
        right: 10px;
    }
}
