#scrollingTabs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  // tabs
  > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 10px;
    > div {
      border: 1px solid #1890ff;
      color: #1890ff;
      font-weight: "500";
      padding: 6px 20px;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        background-color: #1890ff;
        color: white;
      }
    }
    .active {
      background-color: #1890ff;
      color: white;
    }
  }
}
