#drawerTemplate {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  #dpDrawer {
    background-color: #2f4858;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    transition: all 0.1s ease;
    position: relative;
    
    &.expanded {
      min-width: 300px;
      width: 300px;
    }
    
    &.collapsed {
      min-width: 70px;
      width: 70px;
      overflow: hidden;
    }
    
    #dpdpic {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto;
      font-weight: 600;
      transition: all 0.4s ease;
      
      &.compact {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
    }
    
    .firmInitialCircle {
      color: #2f4858;
      font-size: 22px;
      font-weight: bold;
    }
    
    #dpdCompanyName {
      margin: 10px auto;
      color: #b1b1b1;
      font-size: small;
      max-width: 300px;
    }
    
    #dpdEmail {
      margin: 0px auto;
      color: #b1b1b1;
      font-size: small;
      margin-bottom: 50px;
    }
    
    .dpdMenuItem {
      display: flex;
      flex-direction: row;
      gap: 10px;
      color: white;
      height: 45px;
      align-items: center;
      padding: 0px 20px;
      margin-bottom: 15px;
      cursor: pointer;
      transition: background-color 0.8s ease;
      
      > :nth-child(n) {
        color: white;
      }
      
      &:hover {
        background-color: #496f87;
      }
      
      &.dpdMenuItemActive {
        background-color: #496f87;
      }
      
      &.iconOnly {
        justify-content: center;
        padding: 0px;
        
        svg {
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }
  
  #drawerContent {
    display: flex;
    flex: 1;
    overflow: hidden;
    transition: all 0.8s ease;
    
    > * {
      /* Add this to ensure child components fill the space */
      width: 100%;
      height: 100%;
    }
  }
}